import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import StepperMui from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import { useTrackContext } from '../../contexts/TrackContext';
import { steps } from '../../constants';
import StepIcon from './StepIcon';
import { useConnectorStyles } from './style';

const Stepper = () => {
  const connectorStyles = useConnectorStyles();
  const [activeStep, setActiveStep] = useState();
  const [alternativeLabels, setAlternativeLabels] = useState({});
  const { data: { status } } = useTrackContext();

  const calculateCurrentStep = () => {
    steps.forEach(({ isCurrent, alternatives }, index) => {
      if (isCurrent(status)) {
        setActiveStep(index);
      } else if (alternatives) {
        alternatives.forEach((alternative) => {
          if (alternative.isCurrent(status)) {
            setAlternativeLabels((actualAlternativeLabels) => ({
              ...actualAlternativeLabels,
              [index]: alternative.label,
            }));

            setActiveStep(index);
          }
        });
      }
    });
  };

  useEffect(calculateCurrentStep, [steps, status]);

  return (
    <>
      <Grid item>
        <Typography
          variant="h4"
          align="center"
          color="textSecondary"
        >
          <b>{steps[activeStep] && steps[activeStep].label.toUpperCase()}</b>
        </Typography>
      </Grid>
      <Grid item>
        <StepperMui
          alternativeLabel
          connector={<StepConnector classes={connectorStyles} />}
          activeStep={activeStep}
        >
          {steps.map(({ label }, index) => (
            <Step key={label}>
              <StepLabel StepIconComponent={StepIcon}>
                {alternativeLabels[index] || label}
              </StepLabel>
            </Step>
          ))}
        </StepperMui>
      </Grid>
    </>
  );
};

export default Stepper;
