import React from 'react';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { useTrackContext } from '../../contexts/TrackContext';
import useStyles from './style';

const Statistics = () => {
  const { card } = useStyles();
  const { data: { order: { co2 } } } = useTrackContext();

  if (!co2) return null;

  return (
    <Card className={card} elevation={0}>
      <Typography variant="h4"><b>{`${co2}g`}</b></Typography>
      <Typography variant="caption">
        <b>CO2 risparmiata con questo ordine</b>
      </Typography>
    </Card>
  );
};

export default Statistics;
