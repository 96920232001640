import React, { Fragment } from 'react';
import uniqueId from 'lodash/uniqueId';
import groupBy from 'lodash/groupBy';
import { format } from 'date-fns';
import startCase from 'lodash/startCase';
import * as dateFnsLocales from 'date-fns/locale';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import { useTrackContext } from '../../contexts/TrackContext';
import Item from './Item';

const Log = () => {
  const { data: { log } } = useTrackContext();
  const locale = dateFnsLocales.it;

  const groupedLogs = groupBy(log, ({ created }) => (
    format(new Date(created.replace(' ', 'T')), 'eeee d MMM', { locale })
  ));

  return (
    <List>
      {Object.keys(groupedLogs).map((date) => ([
        <ListSubheader key={date}>{startCase(date)}</ListSubheader>,
        <Fragment key={`${date}-items`}>
          {groupedLogs[date].map(({
            id,
            created,
            description,
            address,
            note,
          }) => (
            <Item
              key={uniqueId(id)}
              created={created.replace(' ', 'T')}
              description={description}
              address={address}
              note={note}
            />
          ))}
        </Fragment>,
      ]))}
    </List>
  );
};

export default Log;
