import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import { useTrackContext } from '../../contexts/TrackContext';
import Entity from './Entity';
import useStyles from './style';

const OrderDetail = () => {
  const { card, cardContent, action } = useStyles();
  const { data: { order } } = useTrackContext();
  const {
    id,
    pickupAddress,
    pickupName,
    pickupCompany,
    pickupEmail,
    pickupLat,
    pickupLng,
    deliveryAddress,
    deliveryCompany,
    deliveryName,
    deliveryEmail,
    deliveryLat,
    deliveryLng,
  } = order;

  return (
    <Card className={card} elevation={4}>
      <CardHeader title={`#${id}`} classes={{ action }} />
      <Divider variant="middle" />
      <CardContent className={cardContent}>
        <List>
          <Entity
            type="Partenza"
            name={pickupCompany || pickupName}
            address={pickupAddress}
            email={pickupEmail}
            coordinates={`${pickupLat},${pickupLng}`}
          />
          <Entity
            type="Arrivo"
            name={deliveryCompany || deliveryName}
            address={deliveryAddress}
            email={deliveryEmail}
            coordinates={`${deliveryLat},${deliveryLng}`}
          />
        </List>
      </CardContent>
    </Card>
  );
};

export default OrderDetail;
