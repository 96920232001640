import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { TrackProvider } from './contexts/TrackContext';
import AppBar from './components/AppBar';
import Container from './components/Container';
import Content from './components/Content';
import Footer from './components/Footer';

const App = () => (
  <>
    <CssBaseline />
    <AppBar />
    <TrackProvider>
      <Container>
        <Content />
      </Container>
    </TrackProvider>
    <Footer />
  </>
);

export default App;
