import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  main: {
    maxWidth: '50%',
    alignSelf: 'center',
  },
});

export default useStyles;
