import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  card: {
    width: '100%',
  },

  cardContent: {
    padding: 0,
    paddingBottom: '0 !important',
  },

  action: {
    margin: 0,
  },

  entityText: {
    paddingRight: `calc(150px + ${spacing(2)}px)`,
    [breakpoints.down('xs')]: {
      paddingRight: 0,
    },
  },
}));

export default useStyles;
