import React from 'react';
import { useTheme } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTrackContext } from '../../contexts/TrackContext';
import Error from '../Error';
import Stepper from '../Stepper';
import Log from '../Log';
import OrderDetail from '../OrderDetail';
import Statistics from '../Statistics';

const Content = () => {
  const { breakpoints } = useTheme();
  const isMobileView = useMediaQuery(breakpoints.down('xs'));
  const { error, loading } = useTrackContext();

  if (error) return <Error />;
  if (loading) return <CircularProgress />;

  return (
    <Grid container direction="column" spacing={4}>
      <Stepper />
      <Grid item>
        <Grid
          container
          justify="center"
          spacing={4}
          direction={isMobileView ? 'column-reverse' : 'row'}
        >
          <Grid item xs={12} md={6} lg={5}>
            <Grid container justify="center">
              <Log />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <Grid
              container
              justify="center"
              direction="column"
              spacing={4}
            >
              <Grid item xs>
                <OrderDetail />
              </Grid>
              <Grid item xs>
                <Statistics />
              </Grid>
              <Grid item xs>
                <Typography
                  align="center"
                  color="primary"
                  variant="h4"
                >
                  #facciamomenorumore
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Content;
