import { makeStyles } from '@material-ui/styles';
import cloud from '../../assets/cloud-background.png';

const useStyles = makeStyles(({ palette: { primary, common }, spacing, breakpoints }) => ({
  card: {
    backgroundColor: primary.main,
    color: common.white,
    textAlign: 'center',
    padding: spacing(1),
    backgroundImage: `url(${cloud})`,
    backgroundPosition: 'bottom right',
    backgroundPositionX: '105%',
    backgroundSize: '25%',
    backgroundRepeat: 'no-repeat',
    [breakpoints.down('sm')]: {
      paddingLeft: spacing(2),
      textAlign: 'left',
    },
  },
}));

export default useStyles;
