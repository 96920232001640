import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import useStyles from './style';

const Item = ({
  created,
  description,
  address,
  note,
}) => {
  const { text, alert } = useStyles();

  return (
    <>
      <ListItem>
        <ListItemAvatar>
          <Typography>
            {format(new Date(created), 'H:mm')}
          </Typography>
        </ListItemAvatar>
        <ListItemText
          className={text}
          primary={description}
          secondary={(
            <>
              {address}
              {note ? (
                <Alert
                  severity="warning"
                  className={alert}
                >
                  {note}
                </Alert>
              ) : null}
            </>
          )}
        />
      </ListItem>
    </>
  );
};

Item.propTypes = {
  created: PropTypes.string,
  description: PropTypes.string,
  address: PropTypes.string,
  note: PropTypes.string,
};

Item.defaultProps = {
  created: null,
  description: null,
  address: null,
  note: null,
};

export default Item;
