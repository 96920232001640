import React from 'react';
import classnames from 'classnames';
import AppBarMui from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faVimeoV, faInstagram } from '@fortawesome/free-brands-svg-icons';
import ebcLogo from '../../assets/ebc-logo.png';
import { useMainStyles, useSocialStyles } from './style';

const socialIcons = [{
  label: 'facebook',
  icon: faFacebookF,
  link: 'https://www.facebook.com/ebcgenova',
}, {
  label: 'instagram',
  icon: faInstagram,
  link: 'https://www.instagram.com/ebcgenova/',
}, {
  label: 'vimeo',
  icon: faVimeoV,
  link: 'https://vimeo.com/user67223052',
}];

const AppBar = () => {
  const { main } = useMainStyles();
  const socialStyles = useSocialStyles();

  return (
    <AppBarMui className={main} position="sticky">
      <Toolbar>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <img src={ebcLogo} alt="EBC logo" height={50} />
          </Grid>
          <Grid item xs={3}>
            <Grid container spacing={3} justify="flex-end">
              {socialIcons.map(({ icon, label, link }) => (
                <Grid item key={label}>
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon
                      size="2x"
                      icon={icon}
                      className={classnames([socialStyles.main, socialStyles[label]])}
                    />
                  </a>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBarMui>
  );
};

export default AppBar;
