import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette: { grey }, spacing }) => ({
  text: {
    borderLeft: `2px solid ${grey[300]}`,
    paddingLeft: spacing(3),
  },

  alert: {
    marginTop: spacing(2),
  },
}));

export default useStyles;
