import React from 'react';
import PropTypes from 'prop-types';
import ContainerMui from '@material-ui/core/Container';
import useStyles from './style';

const Container = ({ children }) => {
  const { main } = useStyles();

  return (
    <ContainerMui className={main}>
      {children}
    </ContainerMui>
  );
};

Container.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

export default Container;
