import React, {
  createContext,
  useContext,
  useReducer,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import camelcaseKeys from 'camelcase-keys';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const { REACT_APP_EBC_API_URL: ebcApiUrl } = process.env;

const TrackContext = createContext([]);

const initialState = {
  data: {},
  error: false,
  loading: true,
};

const trackingReducer = (state, { type, payload }) => {
  switch (type) {
    case 'loading':
      return {
        ...initialState,
        loading: true,
      };
    case 'error':
      return {
        ...initialState,
        loading: false,
        error: true,
      };
    case 'success':
      return {
        ...initialState,
        loading: false,
        data: camelcaseKeys(payload, { deep: true }),
      };

    default: return state;
  }
};

const TrackProvider = ({ children }) => {
  const { trackId } = useParams();
  const [state, dispatch] = useReducer(trackingReducer, initialState);

  useEffect(() => {
    const fetchTracking = async () => {
      try {
        dispatch({ type: 'loading' });
        const result = await axios(`${ebcApiUrl}/tracking/${trackId}`);
        dispatch({ type: 'success', payload: result.data });
      } catch {
        dispatch({ type: 'error' });
      }
    };

    fetchTracking();
  }, [trackId]);

  return (
    <TrackContext.Provider value={state}>
      {children}
    </TrackContext.Provider>
  );
};

const useTrackContext = () => useContext(TrackContext);

TrackProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export {
  TrackContext,
  TrackProvider,
  useTrackContext,
};
