import { makeStyles } from '@material-ui/styles';

const useConnectorStyles = makeStyles(({ palette: { primary, grey } }) => ({
  root: {
    top: 10,
    left: 'calc(-50% + 5px)',
    right: 'calc(50% + 5px)',
  },

  active: {
    top: 6,
    left: 'calc(-50% + 5px)',
    right: 'calc(50% + 5px)',
    borderTop: `6px solid ${primary.main}`,
    borderBottom: `6px solid ${primary.main}`,
    '& $line': {
      border: 0,
    },
  },

  completed: {
    top: 6,
    left: 'calc(-50% + 5px)',
    right: 'calc(50% + 5px)',
    borderTop: `6px solid ${primary.main}`,
    borderBottom: `6px solid ${primary.main}`,
    '& $line': {
      border: 0,
    },
  },

  line: {
    borderColor: grey[300],
    borderTopWidth: 2,
    borderTopStyle: 'solid',
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
  },
}));

const useStepStyles = makeStyles(({ palette: { common, primary, grey } }) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 24,
    height: 24,
    borderRadius: '50%',
    color: common.white,
    backgroundColor: grey[300],
    zIndex: 10,
  },

  completed: {
    backgroundColor: primary.main,
  },
}));

export { useConnectorStyles, useStepStyles };
