import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette: { text, common }, spacing }) => ({
  main: {
    backgroundColor: text.secondary,
    color: common.white,
    padding: spacing(4),
    marginTop: spacing(8),
  },
}));

export default useStyles;
