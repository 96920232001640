import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Dot from '@material-ui/icons/FiberManualRecord';
import { useStepStyles } from './style';

const StepIcon = ({ active, completed }) => {
  const { root, completed: completedStyle } = useStepStyles();

  return (
    <div className={classNames(root, { [completedStyle]: completed || active })}>
      {completed || active ? <Dot fontSize="small" /> : null}
    </div>
  );
};

StepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};

StepIcon.defaultProps = {
  active: false,
  completed: false,
};

export default StepIcon;
