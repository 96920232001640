import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette: { background }, spacing }) => ({
  main: {
    backgroundColor: background.paper,
    paddingTop: spacing(6),
  },
}));

export default useStyles;
