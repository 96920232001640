const steps = [{
  label: 'Ordine ricevuto',
  isCurrent: (status) => (+status === 0),
}, {
  label: 'Pronto per la consegna',
  isCurrent: (status) => (+status === 1),
  alternatives: [{
    label: 'Eccezione!',
    isCurrent: (status) => (+status === 2),
  }],
}, {
  label: 'In consegna',
  isCurrent: (status) => (+status === 3),
}, {
  label: 'Consegnato',
  isCurrent: (status) => (+status === 4),
}];

// eslint-disable-next-line import/prefer-default-export
export { steps };
