import React from 'react';
import Alert from '@material-ui/lab/Alert';
import useStyles from './style';

const alertText = 'Il codice tracking non è valido, oppure non è stato ancora caricato a sistema. Riprova tra qualche minuto.';

const Error = () => {
  const { main } = useStyles();

  return (
    <Alert severity="error" className={main}>
      {alertText}
    </Alert>
  );
};

export default Error;
