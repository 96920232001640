const overrides = {
  MuiCssBaseline: {
    '@global': {
      body: {
        backgroundColor: 'white',
      },
    },
  },
};

export default overrides;
