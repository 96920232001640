import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import useStyles from './style';

const Footer = () => {
  const { main } = useStyles();

  return (
    <Grid container justify="center" className={main}>
      <Typography>
        <Link href="https://www.ebcgenova.it/" target="_blank">© EBC Green Logistics</Link>
        {' '}
        | Via Mura Della Marina 6/8 R, 16128 - Genova | P.IVA 02060730997
      </Typography>
    </Grid>
  );
};

export default Footer;
