import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Hidden from '@material-ui/core/Hidden';
import useStyles from './style';

const Entity = ({
  type,
  name,
  address,
  email,
  coordinates,
}) => {
  const { entityText } = useStyles();

  const googleMapsParameters = {
    size: '150x100',
    center: coordinates,
    zoom: 13,
    key: 'AIzaSyAHcQC8LhnTVZu-IIGm7foXK7GtG6Ine3U',
    markers: `size:tiny|${coordinates}`,
  };

  const parameters = Object
    .keys(googleMapsParameters)
    .map((key) => `${key}=${googleMapsParameters[key]}`)
    .join('&');

  const googleMapsLink = `https://www.google.com/maps/place/${coordinates}`;
  const googleMapsStaticImage = `https://maps.googleapis.com/maps/api/staticmap?${parameters}`;

  return (
    <ListItem>
      <ListItemText className={entityText}>
        <Typography variant="h5">{type}</Typography>
        <Typography>{name}</Typography>
        <Typography>{address}</Typography>
        <Typography>
          <Link
            href={`mailto:${email}`}
            target="_blank"
            rel="noreferrer"
          >
            {email}
          </Link>
        </Typography>
      </ListItemText>
      <Hidden xsDown>
        <ListItemSecondaryAction>
          <Link
            href={googleMapsLink}
            target="_blank"
            rel="noreferrer"
          >
            <img src={googleMapsStaticImage} alt="google-maps" />
          </Link>
        </ListItemSecondaryAction>
      </Hidden>
    </ListItem>
  );
};

Entity.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  coordinates: PropTypes.string.isRequired,
};

export default Entity;
