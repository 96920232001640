import { makeStyles } from '@material-ui/styles';

const useMainStyles = makeStyles(({ mixins, palette: { background, common }, spacing }) => ({
  main: {
    backgroundColor: background.paper,
    color: common.black,
    padding: spacing(1),
  },

  offset: mixins.toolbar,
}));

const useSocialStyles = makeStyles(({
  palette: {
    text,
    facebook,
    instagram,
    vimeo,
  },
}) => ({
  main: {
    color: text.secondary,
    transition: 'color 150ms ease',
  },

  facebook: { '&:hover': { color: facebook.main } },
  instagram: { '&:hover': { color: instagram.main } },
  vimeo: { '&:hover': { color: vimeo.main } },
}));

export { useMainStyles, useSocialStyles };
