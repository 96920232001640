const typography = {
  primary: {
    main: '#00BB31',
    contrastText: '#FFF',
  },

  text: {
    secondary: '#474849',
  },

  facebook: { main: '#0B8FF3' },
  instagram: { main: '#EA0F0E' },
  vimeo: { main: '#04ACF4' },
};

export default typography;
